import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useOrdersStore from '../store/useOrdersStore'
import {
  Fade,
  Grid2,
  IconButton,
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getServerUrl } from '../utils/helpers'
import { isNull, map } from 'lodash'
import dayjs from 'dayjs'
import useImagesStore from '../store/useImagesStore'
import useSketchesStore from '../store/useSketchesStore'

const Container = styled('div')(() => ({
  padding: '1rem',
  height: '100dvh',
  position: 'relative',
}))

const HeaderContainer = styled(Grid2)(() => ({
  marginBottom: '1rem',
}))

const ContentContainer = styled(TableContainer)(() => ({
  height: 'calc(100dvh - 88px)',
  overflowY: 'auto',
  overflowX: 'hidden',
}))

const TableCellName = styled(TableCell)(() => ({
  width: '30%',
}))

const TableCellContent = styled(TableCell)(() => ({
  width: '70%',
}))

const ImageModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const ImageFade = styled(Fade)(() => ({
  outline: 'none',
}))

const ImagesContainer = styled('div')(() => ({
  display: 'flex',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  height: '200px',
  alignItems: 'center',
}))

const OrderDetailPage = () => {
  const orderId = useParams().orderId
  const navigate = useNavigate()
  const { orders, getOrders } = useOrdersStore()
  const { getSelectedOrderImages, orderImages } = useImagesStore()
  const { getSelectedOrderSketches, orderSketches } = useSketchesStore()
  const savedUserData = localStorage.getItem('loginData')

  const [open, setOpen] = useState<boolean>(false)
  const [image, setImage] = useState<string>('')

  const handleClose = () => {
    setOpen(false)
  }

  const handleImage = (value: string) => {
    setImage(value)
    setOpen(true)
  }

  useEffect(() => {
    getOrders()
    getSelectedOrderImages(Number(orderId))
    getSelectedOrderSketches(Number(orderId))
  }, [])

  const orderDetails = orders.find((order) => order.id === Number(orderId))

  return (
    <Container>
      <HeaderContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton
          onClick={() => {
            if (isNull(savedUserData)) {
              navigate('/login')
            } else {
              navigate(-1)
            }
          }}
        >
          <ArrowBackIcon style={{ color: '#000000' }} />
        </IconButton>
        <Typography variant="h5">Zlecenie: {orderDetails?.id}</Typography>
      </HeaderContainer>
      <ContentContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCellName variant="head">
                Imię Nazwisko / Nazwa firmy
              </TableCellName>
              <TableCellContent>{orderDetails?.name}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">NIP</TableCellName>
              <TableCellContent>{orderDetails?.taxId}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Adres</TableCellName>
              <TableCellContent>
                {orderDetails?.postalCode} {orderDetails?.city}{' '}
                {orderDetails?.road}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Telefon kontaktowy</TableCellName>
              <TableCellContent>{orderDetails?.phoneNumber}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Kolor RAL</TableCellName>
              <TableCellContent>{orderDetails?.ralColor}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Podkład</TableCellName>
              <TableCellContent>
                {orderDetails?.base === 'yes' ? 'Tak' : 'Nie'}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Piaskowanie</TableCellName>
              <TableCellContent>
                {orderDetails?.sandblasting === 'yes' ? 'Tak' : 'Nie'}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">PZ</TableCellName>
              <TableCellContent>{orderDetails?.ciNumber}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Data przyjęcia</TableCellName>
              <TableCellContent>
                {dayjs(orderDetails?.date).format('YYYY-MM-DD HH:ss')}
              </TableCellContent>
            </TableRow>
            <TableRow style={{ height: '200px' }}>
              <TableCellName variant="head">Podpis zlecającego</TableCellName>
              <TableCellContent>
                {orderDetails?.signature && (
                  <img
                    src={getServerUrl(orderDetails.signature)}
                    alt="signature"
                  />
                )}
              </TableCellContent>
            </TableRow>
            <TableRow style={{ height: '200px' }}>
              <TableCellName variant="head">Załączone zdjęcia</TableCellName>
              <TableCell style={{ display: 'grid', width: '100% !important' }}>
                <ImagesContainer>
                  {orderImages.length > 0 &&
                    map(orderImages, (orderImage) => (
                      <img
                        onClick={() => handleImage(orderImage.path)}
                        key={orderImage.id}
                        style={{
                          height: '167px',
                          width: 'auto',
                          marginRight: '0.5rem',
                          flexShrink: 0,
                        }}
                        src={getServerUrl(orderImage.path)}
                        alt={String(orderImage.id)}
                      />
                    ))}
                </ImagesContainer>
              </TableCell>
            </TableRow>
            <TableRow style={{ height: '200px' }}>
              <TableCellName variant="head">
                Załączone rysunki techniczne
              </TableCellName>
              <TableCell style={{ display: 'grid', width: '100% !important' }}>
                <ImagesContainer>
                  {orderSketches.length > 0 &&
                    map(orderSketches, (orderSketch) => (
                      <img
                        onClick={() => handleImage(orderSketch.path)}
                        key={orderSketch.id}
                        style={{
                          height: '167px',
                          width: 'auto',
                          marginRight: '0.5rem',
                          flexShrink: 0,
                        }}
                        src={getServerUrl(orderSketch.path)}
                        alt={String(orderSketch.id)}
                      />
                    ))}
                </ImagesContainer>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ImageModal open={open} onClose={handleClose}>
          <ImageFade in={open} timeout={500}>
            <img
              src={getServerUrl(image)}
              alt="asd"
              style={{ maxHeight: '90%', maxWidth: '90%' }}
            />
          </ImageFade>
        </ImageModal>
      </ContentContainer>
    </Container>
  )
}

export default OrderDetailPage
