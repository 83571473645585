import React from 'react'
import {
  Drawer,
  Grid2,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment'
import SettingsIcon from '@mui/icons-material/Settings'
import EngineeringIcon from '@mui/icons-material/Engineering'
import Logo from '../assets/AM_HAMA_logo_250_50.png'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useNavigate } from 'react-router-dom'
import MButton from './material/MButton'
import useAuthStore from '../store/useAuthStore'
import usePrinterStore from '../store/usePrinterStore'
import { isNull } from 'lodash'

const NavigationDrawer = styled(Drawer)(() => ({
  width: '200px',
  '& .MuiDrawer-paper': {
    backgroundColor: '#f2f8fc',
    color: '#000000',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

const NavigationList = styled(List)(() => ({
  width: '200px',
}))

const Image = styled('img')(() => ({
  padding: '1rem 0',
  width: '180px',
  height: 'auto',
}))

const MainNavigation = () => {
  const navigate = useNavigate()
  const { logout } = useAuthStore()
  const { printerStatuses } = usePrinterStore()
  const isActive = (path: string) => window.location.pathname === path
  const savedUserData = localStorage.getItem('loginData')

  const MenuItems = [
    {
      id: 'orders',
      name: 'Zlecenia',
      icon: (
        <AssignmentIcon sx={{ color: isActive('/') ? '#0d539e' : '#000000' }} />
      ),
      path: '/',
    },
    {
      id: 'contractors',
      name: 'Kontrahenci',
      icon: (
        <EngineeringIcon
          sx={{ color: isActive('/contractors') ? '#0d539e' : '#000000' }}
        />
      ),
      path: '/contractors',
    },
    {
      id: 'settings',
      name: 'Ustawienia',
      icon: (
        <SettingsIcon
          sx={{ color: isActive('/settings') ? '#0d539e' : '#000000' }}
        />
      ),
      path: '/settings',
    },
  ]

  const shouldShowWarning = () => {
    return (
      !printerStatuses?.printerStatusMessage ||
      !printerStatuses?.paperStatusMessage ||
      !printerStatuses?.ribbonStatusMessage
    )
  }

  return (
    <NavigationDrawer variant="permanent" anchor="left">
      <Grid2 container direction="column" alignItems="center">
        <Image src={Logo} alt="AM HAMA Logo" />
        <NavigationList>
          {MenuItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                disabled={isNull(savedUserData)}
                onClick={() => navigate(item.path)}
              >
                <ListItemIcon style={{ minWidth: '30px' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  style={isActive(item.path) ? { color: '#0d539e' } : {}}
                  primary={item.name}
                />
                {item.id === 'settings' && shouldShowWarning() && (
                  <WarningAmberIcon style={{ color: '#ed6c02' }} />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </NavigationList>
      </Grid2>
      {!isNull(savedUserData) && (
        <Grid2
          container
          direction="row"
          justifyContent="center"
          style={{ marginBottom: '1rem' }}
        >
          <MButton
            style={{ width: '150px' }}
            variant="contained"
            onClick={() => {
              logout()
              navigate('/login')
            }}
          >
            Wyloguj się
          </MButton>
        </Grid2>
      )}
    </NavigationDrawer>
  )
}

export default MainNavigation
