import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addContractorValidation } from '../utils/validation'
import {
  Grid2,
  IconButton,
  InputAdornment,
  styled,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams } from 'react-router-dom'
import MInput from '../components/material/MInput'
import SyncIcon from '@mui/icons-material/Sync'
import useAuthStore from '../store/useAuthStore'
import { useNotification } from '../context/NotificationContext'
import MButton from '../components/material/MButton'
import useContractorsStore from '../store/useContractorsStore'

const Container = styled('div')(() => ({
  padding: '1rem',
  height: '100%',
}))

const HeaderContainer = styled(Grid2)(() => ({
  marginBottom: '1rem',
}))

const FormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const AddContractorPage = () => {
  const contractorId = useParams().contractorId
  const { getDatFromGus } = useAuthStore()
  const { openNotification } = useNotification()
  const { updateContractor, addContractor, contractors } = useContractorsStore()
  const navigate = useNavigate()

  const editedObject = contractors.find(
    (contractor) => contractor.id === Number(contractorId),
  )

  const { control, handleSubmit, getValues, setValue, formState } = useForm({
    defaultValues: {
      name: editedObject?.name || '',
      taxId: editedObject?.taxId || '',
      postalCode: editedObject?.postalCode || '',
      city: editedObject?.city || '',
      road: editedObject?.road || '',
      phoneNumber: editedObject?.phoneNumber || '',
    },
    resolver: yupResolver(addContractorValidation),
    mode: 'all',
  })

  const getDetailsFromGus = async () => {
    await getDatFromGus(getValues('taxId') || '')
      .then((data) => {
        setValue('name', data.name)
        setValue('city', data.city)
        setValue('road', data.street)
        setValue('postalCode', data.postal_code)
      })
      .catch((error) => {
        openNotification(error.message, 'error')
      })
  }

  return (
    <Container>
      <HeaderContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon style={{ color: '#000000' }} />
        </IconButton>
        <Typography variant="h5">
          {contractorId ? 'Edytuj kontrahenta' : 'Dodaj kontrahenta'}
        </Typography>
      </HeaderContainer>
      <FormContainer>
        <Grid2 container direction="row" gap={4}>
          <Grid2 direction="column" container sx={{ flex: 1 }}>
            <Controller
              name="taxId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MInput
                  fullWidth
                  variant="outlined"
                  label="NIP"
                  type="text"
                  value={value}
                  onChange={onChange}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={getValues('taxId') === ''}
                            onClick={() => getDetailsFromGus()}
                            edge="end"
                          >
                            <SyncIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            />
            <Controller
              name="name"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Imię Nazwisko / Nazwa firmy"
                  type="text"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.name?.message}
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Telefon kontaktowy"
                  type="tel"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.phoneNumber?.message}
                />
              )}
            />
          </Grid2>
          <Grid2 direction="column" container sx={{ flex: 1 }}>
            <Controller
              name="postalCode"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Kod pocztowy"
                  type="text"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.postalCode?.message}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Miejscowość"
                  type="text"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.city?.message}
                />
              )}
            />
            <Controller
              name="road"
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <MInput
                  required
                  fullWidth
                  variant="outlined"
                  label="Ulica"
                  type="text"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.road?.message}
                />
              )}
            />
          </Grid2>
        </Grid2>
        <Grid2
          style={{ position: 'absolute', bottom: '1rem', right: '1rem' }}
          container
          direction="row"
          justifyContent="flex-end"
        >
          <MButton
            disabled={!formState.isValid}
            variant="contained"
            onClick={handleSubmit(async (values) => {
              if (contractorId) {
                const statusCode = await updateContractor(
                  editedObject!.id,
                  values,
                )
                if (statusCode === 201) {
                  navigate(-1)
                }
              } else {
                const statusCode = await addContractor(values)

                if (statusCode === 201) {
                  navigate(-1)
                }
              }
            })}
          >
            Zapisz
          </MButton>
        </Grid2>
      </FormContainer>
    </Container>
  )
}

export default AddContractorPage
