import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { OrderImage } from '../types/image'
import { NotificationService } from '../service/NotificationService'

interface Store {
  orderImages: OrderImage[]
  imagesToUpload: File[]
  setImagesToUpload: (imagesToUpload: File[]) => void
  getSelectedOrderImages: (orderId?: number) => Promise<OrderImage[]>
  uploadImage: (orderId: number) => Promise<number>
  deleteImage: (imageId?: number) => Promise<number>
  removeImageToUpload: (imageToDelete: File) => void
}

const useImagesStore = create<Store>((set, get) => ({
  orderImages: [],
  imagesToUpload: [],

  setImagesToUpload: (imagesToUpload: File[]) => {
    set((state) => ({
      imagesToUpload: [...state.imagesToUpload, ...imagesToUpload],
    }))
  },

  removeImageToUpload: (imageToDelete: File) => {
    set((state) => ({
      imagesToUpload: state.imagesToUpload.filter(
        (image) => image !== imageToDelete,
      ),
    }))
  },

  getSelectedOrderImages: async (orderId) => {
    await callApi<{ data: OrderImage[] }>(`/api/images/${orderId}`, 'GET').then(
      (res) => {
        set(() => ({ orderImages: res.data }))
      },
    )

    return get().orderImages
  },

  uploadImage: async (orderId) => {
    if (get().imagesToUpload.length > 0) {
      const formData = new FormData()

      get().imagesToUpload.forEach((image) => {
        formData.append('images', image)
      })

      return await callApi<{ data: { message: string }; status: number }>(
        `/api/images/${orderId}`,
        'POST',
        formData,
      )
        .then((res) => {
          set(() => ({ imagesToUpload: [] }))
          return res.status
        })
        .catch((error) => {
          NotificationService.showNotification(error.message, 'error')
          return error.status
        })
    }
  },

  deleteImage: async (imageId) =>
    await callApi<{ data: { message: string }; status: number }>(
      `/api/images/${imageId}`,
      'DELETE',
    )
      .then((res) => {
        set((state) => ({
          orderImages: state.orderImages.filter(
            (image) => image.id !== imageId,
          ),
        }))
        return res.status
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
        return error.status
      }),
}))

export default useImagesStore
