import axios, { AxiosResponse } from 'axios'

export const getServerUrl = (path?: string) => {
  return window.location.origin + '/' + path?.replace(/^\//, '')
}

export const toDataURL = (url: string) =>
  axios.get(url, { responseType: 'blob' }).then((response: AxiosResponse) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(response.data)
    })
  })

export const dataUrlToFile = (dataURI: string) => {
  const arr = dataURI.split(',')
  const mime = arr[0].match(/:(.*?);/)?.[1]
  const bstr = atob(arr[1])

  const u8arr = Uint8Array.from(bstr, (char) => char.charCodeAt(0))

  return new File([u8arr], `converted-sketch-${Date.now()}`, { type: mime })
}
