import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'

interface DeleteDialogProps {
  open: boolean
  closeDialog: () => void
  deleteOrder: () => void
  message: string
}

const DeleteDialog = ({
  open,
  closeDialog,
  deleteOrder,
  message,
}: DeleteDialogProps) => {
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Usuwanie zlecenia</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Anuluj</Button>
        <Button onClick={deleteOrder}>Usuń</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
