import {
  AppBar,
  Button,
  Dialog,
  Grid2,
  IconButton,
  Slide,
  Slider,
  Toolbar,
  Typography,
} from '@mui/material'
import React, { forwardRef, useRef, useState } from 'react'
import { ReactSketchCanvas, ReactSketchCanvasRef } from 'react-sketch-canvas'
import { TransitionProps } from '@mui/material/transitions'
import CloseIcon from '@mui/icons-material/Close'
import CreateIcon from '@mui/icons-material/Create'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import BackspaceIcon from '@mui/icons-material/Backspace'
import MButton from '../material/MButton'

interface SketchDrawerProps {
  handleSketchesChange: (canvasData: string) => Promise<void>
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SketchDrawer = ({ handleSketchesChange }: SketchDrawerProps) => {
  const canvasRef = useRef<ReactSketchCanvasRef>(null)
  const [eraseMode, setEraseMode] = useState<boolean>(false)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [strokeWidth, setStrokeWidth] = useState<number | number[]>(5)
  const [eraserWidth, setEraserWidth] = useState<number | number[]>(10)

  const handleClickOpen = () => {
    setOpenDrawer(true)
  }

  const handleClose = () => {
    setOpenDrawer(false)
  }

  const handleEraserClick = () => {
    setEraseMode(true)
    canvasRef.current?.eraseMode(true)
  }

  const handlePenClick = () => {
    setEraseMode(false)
    canvasRef.current?.eraseMode(false)
  }

  const handleUndoClick = () => {
    canvasRef.current?.undo()
  }

  const handleRedoClick = () => {
    canvasRef.current?.redo()
  }

  const handleResetClick = () => {
    canvasRef.current?.resetCanvas()
  }

  const handleAddSketch = async () => {
    const canvasData = await canvasRef?.current?.exportImage('png')
    if (canvasData) {
      await handleSketchesChange(canvasData)
    }
    setOpenDrawer(false)
  }

  return (
    <>
      <MButton variant="contained" onClick={handleClickOpen}>
        Dodaj rysunek techniczny
      </MButton>
      <Dialog
        fullScreen
        open={openDrawer}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#0d539e' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Rysunek techniczny
            </Typography>
            <Button autoFocus color="inherit" onClick={handleAddSketch}>
              Dodaj
            </Button>
          </Toolbar>
        </AppBar>
        <Grid2
          container
          direction="column"
          style={{ margin: '1rem', height: '100%' }}
          gap={4}
        >
          <Grid2 container direction="column" gap={2}>
            <Typography variant="h5">Narzędzia</Typography>
            <Grid2 container direction="row" gap={2}>
              <MButton
                disabled={!eraseMode}
                onClick={handlePenClick}
                style={!eraseMode ? { backgroundColor: '#3a7ace' } : {}}
              >
                <CreateIcon style={{ color: '#ffffff' }} />
              </MButton>
              <MButton
                disabled={eraseMode}
                onClick={handleEraserClick}
                style={eraseMode ? { backgroundColor: '#3a7ace' } : {}}
              >
                <BackspaceIcon style={{ color: '#ffffff' }} />
              </MButton>
              <MButton onClick={handleUndoClick}>
                <UndoIcon style={{ color: '#ffffff' }} />
              </MButton>
              <MButton onClick={handleRedoClick}>
                <RedoIcon style={{ color: '#ffffff' }} />
              </MButton>
              <MButton onClick={handleResetClick}>
                <DeleteForeverIcon style={{ color: '#ffffff' }} />
              </MButton>
            </Grid2>
            <Grid2 container direction="row" gap={2}>
              <Grid2 container style={{ width: 'calc(50% - 8px)' }}>
                <Typography
                  style={{ height: '20px' }}
                  id="erase-slider"
                  gutterBottom
                >
                  Grubość lini
                </Typography>
                <Slider
                  size="medium"
                  value={strokeWidth}
                  onChange={(_event, value) => setStrokeWidth(value)}
                  valueLabelDisplay="auto"
                  min={1}
                  max={20}
                  aria-labelledby="stroke-slider"
                />
              </Grid2>
              <Grid2 container style={{ width: 'calc(50% - 8px)' }}>
                <Typography
                  style={{ height: '20px' }}
                  id="erase-slider"
                  gutterBottom
                >
                  Grubość gumki
                </Typography>
                <Slider
                  size="medium"
                  value={eraserWidth}
                  onChange={(_event, value) => setEraserWidth(value)}
                  valueLabelDisplay="auto"
                  min={1}
                  max={20}
                  aria-labelledby="erase-slider"
                />
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 container sx={{ height: 'calc(100% - 212px)' }}>
            <ReactSketchCanvas
              ref={canvasRef}
              strokeWidth={strokeWidth as number}
              eraserWidth={eraserWidth as number}
              strokeColor="#000000"
            />
          </Grid2>
        </Grid2>
      </Dialog>
    </>
  )
}

export default SketchDrawer
