import { Autocomplete, styled, TextField } from '@mui/material'
import React, { SyntheticEvent } from 'react'
import { get } from 'lodash'

const CustomAutoComplete = styled(Autocomplete)(() => ({
  width: '300%',
  height: '90px',
  '& div': {
    backgroundColor: '#ffffff',
  },
}))

export interface AutoCompleteOption {
  label: string
  value: string
}

interface MAutocompleteProps {
  label: string
  options: AutoCompleteOption[]
  fullWidth?: boolean
  value: string
  onChange: (value: string) => void
}

const MAutocomplete = ({
  fullWidth,
  options,
  label,
  value,
  onChange,
}: MAutocompleteProps) => {
  return (
    <CustomAutoComplete
      style={fullWidth ? { width: '100%' } : {}}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} />}
      value={options.find((option) => option.value === value) || ''}
      onChange={(_: SyntheticEvent<Element, Event>, newValue: unknown) => {
        onChange((newValue as AutoCompleteOption).value)
      }}
      getOptionLabel={(option) => get(option, 'label', '')}
      getOptionKey={(option) => get(option, 'value', '')}
      disableClearable
    />
  )
}

export default MAutocomplete
