import React from 'react'
import { RouteObject } from 'react-router-dom'
import App from '../App'
import LoginPage from '../pages/LoginPage'
import ProtectedRoute from '../components/ProtectedRoute'
import OrdersPage from '../pages/OrdersPage'
import DefaultLayout from '../components/DefaultLayout'
import SettingsPage from '../pages/SettingsPage'
import OrderDetailPage from '../pages/OrderDetailPage'
import AddOrderPage from '../pages/AddOrderPage'
import QRScannerPage from '../pages/QRScannerPage'
import NotFound from '../pages/NotFound'
import ContractorsPage from '../pages/ContractorsPage'
import AddContractorPage from '../pages/AddContractorPage'
import ContractorDetailPage from '../pages/ContractorDetailPage'

const routes: RouteObject[] = [
  {
    path: '',
    element: <App />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: '/',
        element: <DefaultLayout />,
        children: [
          {
            index: true,
            element: <ProtectedRoute children={<OrdersPage />} />,
          },
          {
            path: 'settings',
            element: <ProtectedRoute children={<SettingsPage />} />,
          },
          { path: 'orders/:orderId', element: <OrderDetailPage /> },
          {
            path: 'orders/add-order',
            element: <ProtectedRoute children={<AddOrderPage />} />,
          },
          {
            path: 'orders/edit-order/:orderId',
            element: <ProtectedRoute children={<AddOrderPage />} />,
          },
          {
            path: 'scan-qr',
            element: <ProtectedRoute children={<QRScannerPage />} />,
          },
          {
            path: 'contractors',
            element: <ProtectedRoute children={<ContractorsPage />} />,
          },
          {
            path: 'contractors/add-contractor',
            element: <ProtectedRoute children={<AddContractorPage />} />,
          },
          {
            path: 'contractors/:contractorId',
            element: <ProtectedRoute children={<ContractorDetailPage />} />,
          },
          {
            path: '/contractors/edit-contractor/:contractorId',
            element: <ProtectedRoute children={<AddContractorPage />} />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]

export default routes
